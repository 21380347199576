<template>
  <div
    class="pb-5 mb-2 jft-78"
    :class="currentTab == 'CandidateInbox' ? 'pt-3' : ''"
  >
    <div class="documentScrolling">
      <div class="scratch-pad">
        <CCard class="modal-primary">
          <CCardHeader class="modal-header">
            <div
              class="
                card-header-title
                d-flex
                align-items-center
                justify-content-between
              "
              :class="currentTab == 'CandidateInbox' ? 'jk-40' : ''"
            >
              <div class="col-md-2">
                <input
                  v-if="getFilterOnly.length && currentTab != 'Sent'"
                  type="checkbox"
                  :checked="selectAll"
                  @click="selectAllRow($event)"
                />
                <a
                  v-if="messageList.length"
                  class="text-primary"
                  @click="markAsReadMessageList(messageList,true)"
                  >{{ btnText }}</a
                >
              </div>
              <div class="col-10 pr-0" v-if="currentTab != 'CandidateInbox'">
                <div class="input-group float-right w-100">
                  <input
                    type="text"
                    class="form-control m-0 search"
                    placeholder="Search by Email, Subject and Candidate Id"
                    v-model="filter"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-secondary white"
                      type="button"
                      title="Search"
                      @click="searchTerm"
                      :disabled="!filter.length"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                    <button
                      v-if="currentTab != 'Sent'"
                      class="btn btn-secondary white ml-1"
                      type="button"
                      title="Compose Email"
                      @click="composeEmail"
                    >
                      <i class="fa fa-paper-plane mr-1"></i>
                      <b v-if="composeBtn">Compose</b>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </CCardHeader>
          <CCardBody
            class="scroll-area-lg p-0"
            v-if="emailConversationList.length"
          >
            <div class="to-do-content">
              <ul class="list-group list-group-flush">
                <li
                  class="list-group-item"
                  v-for="(row, index) in emailConversationList"
                  :key="index"
                  :class="
                    row.read == true || currentTab == 'Sent' ? 'read' : null
                  "
                >
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper row">
                      <div
                        class="widget-content-left mr-2 wd-8 col-md-1 col-lg-1"
                      >
                        <div class="custom-checkbox" color="primary">
                          <input
                            v-if="currentTab != 'Sent'"
                            type="checkbox"
                            :value="row.message_id"
                            :disabled="row.read"
                            :checked="messageList.includes(row.message_id)"
                            @click="selectedRow(row.message_id, $event)"
                          />
                          <i
                            v-if="currentTab != 'Sent'"
                            :class="
                              row.read == true
                                ? 'fas fa-envelope-open'
                                : 'fas fa-envelope'
                            "
                            class="text-primary"
                          ></i>
                          <i
                            v-else
                            class="text-primary fas fa-envelope-open"
                          ></i>
                          <i
                            v-if="row.attachment[0]"
                            class="fa fa-paperclip icon"
                          ></i>
                        </div>
                      </div>
                      <div class="widget-content-left col-md-4 col-lg-4">
                        <div
                          v-if="currentTab == 'CandidateInbox'"
                          class="cs-32"
                          title="Click here to view all email conversations"
                          v-html="row.sender_name"
                          @click="allEmailConversation(row)"
                        ></div>
                        <div
                          v-else
                          class="cs-32"
                          title="Click here to view all email conversations"
                          v-html="row.email_id"
                          @click="allEmailConversation(row)"
                        ></div>
                        <div class="widget-subheading text-muted">
                          <p class="mb-0">
                            <small class="opacity-6 ml-1 mr-1">
                              <i class="fas fa-calendar" />
                              {{ row.load_datetime }}
                            </small>
                            <span v-if="currentTab != 'CandidateInbox'">
                              <i
                                class="jobLink"
                                v-if="row.job_id"
                                @click="goToJobPage(row.job_id)"
                                >Job ID:{{ row.job_id }}</i
                              >
                              <i
                                class="jobLink fj-90"
                                v-if="row.candidate_uid"
                                @click="goToUserPage(row.candidate_uid)"
                                >UID:{{ row.candidate_uid }}</i
                              >
                            </span>
                          </p>
                        </div>
                      </div>

                      <div class="widget-content-left col-md-6 col-lg-6">
                        <div
                          class="subject cs-32"
                          title="Click here to view subject conversations"
                          v-html="row.subject"
                          @click="subjectEmailConversation(row)"
                        ></div>
                        <div class="message">
                          <span v-html="getBody(row.body)"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </CCardBody>
          <CCardBody class="scroll-area-lg p-0" v-else>
            <div class="to-do-content">
              <ul class="list-group list-group-flush" colspan="4">
                <li class="my-5">
                  <div class="text-center my-5">
                    <h2>
                      {{ btnNothing }}
                      <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
                    </h2>
                  </div>
                </li>
              </ul></div
          ></CCardBody>
        </CCard>
      </div>
    </div>
    <EmailModal
      v-if="emailModal.isShow"
      :isShowPopup="emailModal.isShow"
      :email_id="emailModal.email_id"
      :emailBox="emailModal.emailBox"
      :sender="emailModal.sender"
      :sender_name="emailModal.sender_name"
      :customer_user_id="emailModal.customer_user_id"
      :email_subject="emailModal.subject"
      :candidate_uid="emailModal.candidate_uid"
      :recruiter_message="emailModal.recruiter_message"
      @emailModalCallBack="ModalCallBack"
      @conversationModalCallBack="conversationModalCallBack"
    />
    <DeleteModal
      v-if="deleteModal.isShowPopup"
      :isShowPopup="deleteModal.isShowPopup"
      :delete_id="deleteModal.delete_id"
      :email_subject="deleteModal.subject"
      @deleteModalCallBack="deleteModalCallBack"
      @emailModalCallBack="modalCallBack"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import EmailModal from "@/containers/Communication/EmailModal";
import DeleteModal from "@/containers/Communication/DeleteModal";
import selectedTagsVue from "../../components/reusable/GenericFilterAndSearch/selectedTags.vue";

export default {
  props: {
    getBoundOnly: {
      type: Array,
      default: null,
    },
    currentTab: {
      type: String,
      default: null,
    },
  },
  components: {
    EmailModal,
    DeleteModal,
  },
  data() {
    return {
      emailModal: {
        isShow: false,
        email_id: "",
        sender: "",
        customer_user_id: "",
        sender_name: "",
        subject: "",
        date_time: "",
        candidate_uid: "",
        emailBox: false,
        recruiter_message: false,
        message_id:null,
      },
      deleteModal: {
        isShowPopup: false,
        delete_id: null,
        subject: null,
      },
      showDialog: {
        email: false,
      },
      messageList: [],
      filter: "",
      btnText: "Mark as read",
      btnNothing: null,
      candidatePageUrl: "/candidate/",
      jobPageUrl: "/job-list-preview/",
      selectAll: false,
      select: false,
    };
  },
  watch: {
    messageList() {
      const msg_ids = this.getFilterOnly.map(v=>v.message_id);
      const unselectedIDs = _.difference(msg_ids,this.messageList)
      if (unselectedIDs.length) {
        this.selectAll = false;
      } else {
        this.selectAll = true;
      }
    },
  },
  computed: {
    ...mapGetters([
      "getUser",
      "noMoreDataFromEmailList",
      "getNotificationEmailMessages",
      "getWindowWidth",
    ]),
    getUniqConversation() {
      let array = [];
      if (this.currentTab == "CandidateInbox") {
        array = _.uniqBy(this.getBoundOnly, (v) =>
          [
            v.sender,
            v.subject.split(": ")[1] ? v.subject.split(": ")[1] : v.subject,
          ].join()
        );
      } else {
        array = _.uniqBy(this.getBoundOnly, (v) =>
          [v.email_id, v.sender, v.subject].join()
        );
      }
      return this.getBoundOnly.length ? array : [];
    },
    getFilterOnly() {
      return this.emailConversationList
        ? _.filter(this.emailConversationList, (message) => {
            if (this.currentTab == "CandidateInbox") {
              if (message.direction === "O" && message.read === false) {
                return message;
              }
            } else {
              if (message.direction === "I" && message.read === false) {
                return message;
              }
            }
          })
        : [];
    },
    emailConversationList() {
      this.getUniqConversation.length == 0
        ? (this.btnNothing = "No Data Found")
        : null;
      return this.getUniqConversation;
    },
    composeBtn() {
      if (this.getWindowWidth > 882) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions(["markAsReadUnread"]),
    subjectEmailConversation(data) {
      if (data.read != true) data.read = true;
      this.emailModal.isShow = true;
      this.emailModal.email_id = data.email_id;
      this.emailModal.sender = data.sender;
      this.emailModal.sender_name = data.sender_name;
      this.emailModal.customer_user_id = data.customer_user_id;
      this.emailModal.candidate_uid = data.candidate_uid;
      this.emailModal.subject = data.subject;
      this.emailModal.recruiter_message = true;
      this.emailModal.message_id = data.message_id;
    },
    ModalCallBack() {
      this.emailModal.isShow = false;
      const msg_ids= this.getAllConversationIDs(this.emailModal.message_id)
      this.markAsReadMessageList(msg_ids,true)
    },
    conversationModalCallBack(delete_id, subject) {
      this.deleteModal.isShowPopup = true;
      this.deleteModal.delete_id = delete_id;
      this.deleteModal.subject = subject;
      this.emailModal.isShow = false;
    },
    deleteModalCallBack() {
      this.deleteModal.isShowPopup = false;
      this.showDialog.email = true;
      this.emailModal.isShow = true;
    },
    modalCallBack(columnKey) {
      this.showDialog[columnKey] = false;
    },
    allEmailConversation(data) {
      if (data.read != true) data.read = true;
      this.emailModal.isShow = true;
      this.emailModal.email_id = data.email_id;
      this.emailModal.sender = data.sender;
      this.emailModal.sender_name = data.sender_name;
      this.emailModal.customer_user_id = data.customer_user_id;
      this.emailModal.candidate_uid = data.candidate_uid;
      this.emailModal.subject = data.subject;
      this.emailModal.recruiter_message = false;
      this.emailModal.message_id = data.message_id;
    },
    goToJobPage(data) {
      this.$router.push(this.jobPageUrl + data);
    },
    goToUserPage(data) {
      this.$router.push(this.candidatePageUrl + data);
    },
    getBody(data) {
      let body = null;
      if (data.includes("<p><br></p>")) {
        body = data.replaceAll("<p><br></p>", "");
        if (body.includes("</p><p>")) {
          body = body.replaceAll("</p><p>", " ");
          return body.length > 90 ? `${body.slice(0, 90)}...` : body;
        }
        return body.length > 90 ? `${body.slice(0, 90)}...` : body;
      } else {
        return data.length > 90 ? `${data.slice(0, 90)}...` : data;
      }
    },
    selectedRow(value, event) {
      if (event.target.checked) {
        this.messageList = [
          ...this.messageList,
          ...this.getAllConversationIDs(value),
        ];
      } else {
        let index = this.messageList.indexOf(value);
        this.messageList.splice(index, 1);
      }
    },
    selectAllRow(event) {
      if (event.target.checked) {
        this.getFilterOnly.forEach((element) => {
          const msg_id = this.getAllConversationIDs(element.message_id);
          this.messageList = [...this.messageList, ...msg_id];
        });
      } else {
        this.messageList = [];
      }
    },
    getAllConversationIDs(message_id) {
      const msg = this.getFilterOnly.find((v) => v.message_id === message_id);
      if (msg ) {
        const { email_id, subject, sender } = msg;
        return _.filter(this.getBoundOnly, { email_id, subject, sender }).map(
          (v) => v.message_id
        );
      }
      return [];
    },
    searchTerm() {
      this.$emit("emailGridCallBack", this.filter);
    },
    composeEmail() {
      this.emailModal.isShow = true;
      this.emailModal.emailBox = true;
      this.emailModal.email_id = null;
      this.emailModal.sender = null;
      this.emailModal.sender_name = null;
      this.emailModal.customer_user_id = null;
      this.emailModal.candidate_uid = null;
      this.emailModal.subject = null;
      this.emailModal.recruiter_message = false;
    },
    markAsReadMessageList(payload,read){
      if(payload.length){
      this.markAsReadUnread({payload,read}).then(res=>{
        this.$parent.notificationTab();
        this.messageList=[];
      })
      }
    }
  },
};
</script>

<style lang="scss" scoped>
$primary: map-get($theme-colors, primary);

.cs-32 {
  cursor: pointer;
}
.cs-32:hover {
  color: blue;
  text-decoration: underline;
}
.custom-checkbox {
  i {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 18px;
  }
}
.card-header {
  padding-left: 0.3rem;
}
.subject {
  font-weight: bolder;
}
a.text-primary {
  margin-left: 6px;
  cursor: pointer;
  color: #eee !important;
}
.message {
  font-size: 10px;
  color: #949393;
}
.modal-header {
  display: block;
}
.search {
  box-shadow: none;
  height: 38px;
}

.jobLink {
  background: #fbf1c0;
  border-radius: 4px;
  padding: 1px;
  margin-left: 3px;
  font-size: 11px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}
.message {
  p {
    margin: 0px;
  }
}
.read {
  background-color: #eee;
}
.icon {
  font-size: 13px !important;
  color: #c8c8c8;
}
.wd-8 {
  min-width: 10%;
}
.fj-90 {
  background-color: #95cfd5;
}
.white {
  color: white;
}
.jk-40 {
  height: 40px;
}
</style>
