<template>
  <div class="container-md col-lg-10 col-md-10 pt-2 organisation-list">
    <div class="pb-0">
      <CRow class="pr-0 pl-0">
        <CCol md="12" sm="12" lg="6">
          <h3 class="text-primary main-title">Email Messages</h3>
        </CCol>
        <CCol md="12" sm="12" lg="6" class="d-none d-lg-block">
          <div class="float-right">
            <CButton
              class="mr-0 nowrap"
              color="primary"
              shape="pill"
              @click="backToHome"
              >Back To Home</CButton
            >
          </div>
        </CCol>
      </CRow>
    </div>
    <div>
      <CRow class="m-0">
        <CCol md="12" sm="12" lg="12">
          <ul
            class="nav nav-bar nav-tabs"
            style="border-bottom:none;float: right;height: 24px;"
          >
            <li
              v-if="getRecruiterEmailMessages.length"
              class="nav-item pt-2 font-style"
            >
              <span class="float-left">
                {{
                  itemFrom +
                    " - " +
                    recruiterItemTo +
                    " " +
                    "of" +
                    " " +
                    getEmailMessagesCount
                }}
              </span>
            </li>
            <li class="nav-item" v-if="getRecruiterEmailMessages.length">
              <CButton
                :disabled="pageCount == 1 ? true : false"
                @click="previousPage(currentTab)"
              >
                <i class="fas fa-angle-left"></i>
              </CButton>
            </li>
            <li class="nav-item" v-if="getRecruiterEmailMessages.length">
              <CButton
                class="pr-0"
                :disabled="
                  pageCount == emailMessagePageCount ||
                  emailMessagePageCount == 0
                    ? true
                    : false
                "
                @click="nextPage(currentTab)"
              >
                <i class="fas fa-angle-right"> </i>
              </CButton>
            </li>
          </ul>
        </CCol>
      </CRow>
    </div>
    <div>
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a
            href="#notification"
            target="_self"
            class="nav-link"
            :class="{ active: currentTab == 'Notification' }"
            @click="activeTab('Notification'), notificationTab('Notification')"
            >Notification</a
          >
        </li>
        <li class="nav-item">
          <a
            href="#inbox"
            target="_self"
            class="nav-link"
            :class="{ active: currentTab == 'Inbox' }"
            @click="activeTab('Inbox'), inboxTab('Inbox')"
            >Inbox</a
          >
        </li>
        <li class="nav-item">
          <a
            href="#sent"
            target="_self"
            class="nav-link"
            :class="{ active: currentTab == 'Sent' }"
            @click="activeTab('Sent'), sentTab('Sent')"
            >Sent</a
          >
        </li>
      </ul>
    </div>
    <div>
      <div class="tab-content">
        <div
          class="tab-pane"
          :class="{ active: currentTab == 'Notification' }"
          :style="currentTab == 'Notification' ? '' : 'display:none;'"
          v-if="currentTab == 'Notification'"
        >
          <NotificationGrid
            :getBoundOnly="getRecruiterEmailMessages"
            :currentTab="currentTab"
            @emailGridCallBack="notificationSearchTerm"
          />
        </div>
        <div
          class="tab-pane"
          :class="{ active: currentTab == 'Inbox' }"
          :style="currentTab == 'Inbox' ? '' : 'display:none;'"
          v-if="currentTab == 'Inbox'"
        >
          <InboxGrid
            :getBoundOnly="getRecruiterEmailMessages"
            :currentTab="currentTab"
            @emailGridCallBack="inboxSearchTerm"
          />
        </div>
        <div
          class="tab-pane"
          :class="{ active: currentTab == 'Sent' }"
          :style="currentTab == 'Sent' ? '' : 'display:none;'"
          v-if="currentTab == 'Sent'"
        >
          <SentGrid
            :getBoundOnly="getRecruiterEmailMessages"
            :currentTab="currentTab"
            @emailGridCallBack="sentSearchTerm"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import NotificationGrid from "@/containers/Communication/EmailGrid";
import InboxGrid from "@/containers/Communication/EmailGrid";
import SentGrid from "@/containers/Communication/EmailGrid";

export default {
  components: {
    NotificationGrid,
    InboxGrid,
    SentGrid,
  },
  data() {
    return {
      currentTab: null,
      pageCount: 1,
      itemsPerPage: 15,
      itemFrom: 1,
      itemTo: 15,
      recruiterItemTo: null,
    };
  },
  watch: {
    getRecruiterEmailMessages() {
      this.recruiterItemTo =
        this.getRecruiterEmailMessages.length <= 15
          ? this.getRecruiterEmailMessages.length + this.itemTo
          : this.getRecruiterEmailMessages.length;
    },
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getRecruiterEmailMessages",
      "emailBoxBackUrl",
      "getNotificationMessagesCount",
      "noMoreDataFromEmailList",
      "getEmailMessagesCount",
    ]),
    ...mapMutations(["getEmailPagination"]),
    getUserEmail() {
      return this.getUser.full_name;
    },
    emailMessagePageCount() {
      let totalCount = Math.ceil(this.getEmailMessagesCount / 15);
      return totalCount;
    },
  },
  methods: {
    ...mapActions([
      "fetchRecruiterEmailMessages",
      "fetchEmailMessagesCount",
      "fetchSearchTermEmailMessages",
      "fetchSearchTermEmailMessagesCount",
    ]),
    backToHome() {
      this.$router.push(this.emailBoxBackUrl);
    },
    activeTab(value) {
      this.pageCount = 1;
      this.itemFrom = 1;
      this.itemTo = 0;
      this.$store.state.communication.getEmailPagination.noMoreDataFromEmailList = false;
      this.currentTab = value;
    },
    previousPage(currentTab) {
      this.pageCount -= 1;
      this.$store.state.communication.getEmailPagination.skip -= 15;
      if (currentTab == "Notification") {
        this.fetchRecruiterEmailMessages({
          sender: this.getUserEmail,
          direction: "I",
        });
        this.itemTo -= this.itemsPerPage;
        this.itemFrom -= this.itemsPerPage;
      } else if (currentTab == "Inbox") {
        this.fetchRecruiterEmailMessages({
          sender: this.getUserEmail,
          direction: "I",
        });
        this.itemTo -= this.itemsPerPage;
        this.itemFrom -= this.itemsPerPage;
      } else if (currentTab == "Sent") {
        this.fetchRecruiterEmailMessages({
          sender: this.getUserEmail,
          direction: "O",
        });
        this.itemTo -= this.itemsPerPage;
        this.itemFrom -= this.itemsPerPage;
      }
    },
    nextPage(currentTab) {
      this.pageCount += 1;
      this.$store.state.communication.getEmailPagination.skip += 15;
      if (currentTab == "Notification") {
        let read = "false";
        let direction = "I";
        this.fetchRecruiterEmailMessages({
          sender: this.getUserEmail,
          read: read,
          direction: direction,
        });
        this.itemFrom += this.itemsPerPage;
        this.itemTo += this.itemsPerPage;
      } else if (currentTab == "Inbox") {
        let direction = "I";
        this.fetchRecruiterEmailMessages({
          sender: this.getUserEmail,
          direction: direction,
        });
        this.itemFrom += this.itemsPerPage;
        this.itemTo += this.itemsPerPage;
      } else if (currentTab == "Sent") {
        let direction = "O";
        this.fetchRecruiterEmailMessages({
          sender: this.getUserEmail,
          direction: direction,
        });
        this.itemFrom += this.itemsPerPage;
        this.itemTo += this.itemsPerPage;
      }
    },
    notificationTab(currentTab) {
      let read = "false";
      let direction = "I";
      this.$store.state.communication.getEmailPagination.skip = 0;
      this.fetchRecruiterEmailMessages({
        sender: this.getUserEmail,
        read: read,
        direction: direction,
      });
      this.fetchEmailMessagesCount({
        sender: this.getUserEmail,
        direction: direction,
        read: read,
      });
    },
    inboxTab(currentTab) {
      let direction = "I";
      this.$store.state.communication.getEmailPagination.skip = 0;
      this.fetchRecruiterEmailMessages({
        sender: this.getUserEmail,
        direction: direction,
      });
      this.fetchEmailMessagesCount({
        sender: this.getUserEmail,
        direction: direction,
      });
    },
    sentTab(currentTab) {
      let direction = "O";
      this.$store.state.communication.getEmailPagination.skip = 0;
      this.fetchRecruiterEmailMessages({
        sender: this.getUserEmail,
        direction: direction,
      });
      this.fetchEmailMessagesCount({
        sender: this.getUserEmail,
        direction: direction,
      });
    },
    notificationSearchTerm(data) {
      let read = "false";
      let direction = "I";
      this.$store.state.communication.getEmailPagination.skip = 0;
      this.fetchSearchTermEmailMessagesCount({
        read: read,
        direction: direction,
        searchTerm: data,
      });
      this.fetchSearchTermEmailMessages({
        read: read,
        direction: direction,
        searchTerm: data,
      });
    },
    inboxSearchTerm(data) {
      let direction = "I";
      this.$store.state.communication.getEmailPagination.skip = 0;
      this.fetchSearchTermEmailMessagesCount({
        direction: direction,
        searchTerm: data,
      });
      this.fetchSearchTermEmailMessages({
        direction: direction,
        searchTerm: data,
      });
    },
    sentSearchTerm(data) {
      let direction = "O";
      this.$store.state.communication.getEmailPagination.skip = 0;
      this.fetchSearchTermEmailMessagesCount({
        direction: direction,
        searchTerm: data,
      });
      this.fetchSearchTermEmailMessages({
        direction: direction,
        searchTerm: data,
      });
    },
  },
  mounted() {
    let read = "false";
    let direction = "I";
    this.$store.state.communication.getEmailPagination.skip = 0;
    if (this.getUserEmail) {
      this.fetchRecruiterEmailMessages({
        sender: this.getUserEmail,
        direction: direction,
        read: read,
      });
      this.fetchEmailMessagesCount({
        sender: this.getUserEmail,
        direction: direction,
        read: read,
      });
    }
    this.pageCount = 1;
    this.itemFrom = 1;
    this.itemTo = 0;
    this.currentTab = "Notification";
  },
  created() {
    window.addEventListener("resize", () => {
      this.$store.commit("SET_WINDOW_WIDTH");
    });
  },
};
</script>

<style lang="scss" scoped>
.nav-bar {
  // padding: 10px 0px 5px 10px;
  float: right;
  height: 39px;
}
.font-style {
  font-weight: 600;
  color: #4f5d73;
  font-size: 12px;
}
@media (max-width: 520px) {
  .nav-bar {
    float: none;
  }
  .nav-tabs {
    border-bottom: none;
  }
}
.input-group-append {
  height: calc(1.5em + 0.75rem + 5px);
}
</style>
